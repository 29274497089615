Array.prototype.shuffle = function () {
  let input = this;

  for (let i = input.length - 1; i >= 0; i--) {

    let randomIndex = Math.floor(Math.random() * (i + 1));
    let itemAtIndex = input[randomIndex];

    input[randomIndex] = input[i];
    input[i] = itemAtIndex;
  }
  return input;
}

export let posts = [
  {
    "id": 1,
    "title": "Arguments with other people.",
    "tag": "relationships",
    "content": "Imagine you are in a heated argument with a coworker, your spouse, or just a friend. Be the one to keep calm, be the reasonable one, you often don't know what the other person is going through and why they are reacting badly to your argument, it could be something completely different than what the argument is about.",
  },
  {
    "id": 2,
    "title": "Do one thing.",
    "tag": "productivity",
    "content": "When you are working try to do only one thing at a time and avoid multitasking. Even listening to something in the background (e.g. podcasts, music with lyrics) may cause you to lose concentration. Focus all your mental energy towards your task at hand and then move on to other things.",
  },
  {
    "id": 3,
    "title": "Sparsity of things.",
    "tag": "consuming",
    "content": "To quote fight club:\nWe buy things we don't need, to impress people we don't like.",
  },
  {
    "id": 4,
    "title": " Intermittent fasting is the way to go.",
    "tag": "health",
    "content": "This advice has to start with a disclaimer, if you have a medical condition, you are very lean, a bearing woman or in general have any medical condition consult with your physician first. \n\nFasting is the process of depriving your body of food during a particular window of time which is usually at least 16 hours and at most 24 hours. In this time frame you may not eat or drink anything that contains calories (coffee and water are acceptable). The benefits of this are to give your body a break from metabolising food all day, it helps to kick start your body into ketosis and starts the autophagy procedure. \n\nThe general idea is not to eat, it will make you leaner, look younger and may prevent some forms of cancer.",
  },
  {
    "id": 5,
    "title": "Sleep is good for you.",
    "tag": "health",
    "content": "Get at least 7 hours of sleep. What is considered normal ,for human beings, is from 7-9 hours of sleep. You need that much sleep in order your body and brain to be able to work at their best.",
  },
  {
    "id": 6,
    "title": "Get creative.",
    "tag": "productivity",
    "content": "When you struggle to be creative or come up with a new concept try this: \nDon't be afraid of failure, it can happen, it is natural to happen a lot of times but don’t let it paralyze you. Every once in a while try something new. Go on this new hiking route, take a different route with the bus, add some randomness to your routine. Lastly get off the path of the least resistance. Our brain is very energy efficient, it will try to connect the dots on things we already know. True creativity lies when we do stuff that are initially hard and then get easier with time. All things you can do now  once were difficult.",
  },
  {
    "id": 7,
    "title": "Read the person.",
    "tag": "work",
    "content": "Never try to strike a deal with someone you find morally indignant. If you gut thinks that this person is no good, trust it, it usually is right.",
  },
  {
    "id": 8,
    "title": "Controling risk with children.",
    "tag": "parenting",
    "content": "Let your children get into a little trouble once in a while, it will make them more responsible and able to deal with life better. Your job as a parent is to keep them out of big trouble.",
  },
  {
    "id": 9,
    "title": "Upside of lacking.",
    "tag": "consuming",
    "content": "When you have less, you are more antifragile to life. You have less to lose, less of a status quo to lose, less financial burdens, less psychological problems.",
  },
  {
    "id": 10,
    "title": "Always be prepared.",
    "tag": "relationships",
    "content": "There was a rumor going around that Aristotle Onassis would have dinner before going on a date. Be prepared and don't get angry when things don't go the way you planned on a date with your spouse.",
  },
  {
    "id": 11,
    "title": "The meaning of life.",
    "tag": "life",
    "content": "Do not wander around asking what the meaning of life is, great philosophers have yet to answer that question. Turn that question around and go ahead and live your life by giving meaning to it.",
  },
  {
    "id": 12,
    "title": "Simple rules.",
    "tag": "productivity",
    "content": "Create some rules in your life (e.g. wash your teeth when you wake up). Keep them really simple, so you can  follow them easily.",
  },
  {
    "id": 13,
    "title": "Limit social media.",
    "tag": "calm",
    "content": "Try to limit your exposure to social media as much as you can. They are not bad. They are a tool. Tools need to be used appropriately. \nDon't compare yourself to others on social media, you don't know what the influencer you are following is doing to show off like that. \nUse social media for what they are, a tool to socialize. Leave the comparison and hierarchy for the real life.",
  },
  {
    "id": 14,
    "title": "Limit Electronics.",
    "tag": "calm",
    "content": "When there is no need for electronic devices, don't use them. If you can do one thing better with a pen and paper, use that. Don't try to emulate everything with electronics. Use it when it makes sense. It will make you robust and you will not lose touch with things that have been around for a very long time.",
  },
  {
    "id": 15,
    "title": "Fully engaged.",
    "tag": "work",
    "content": "Go out in the world and do what you are good at and your mind will be fully focused.",
  },
  {
    "id": 16,
    "title": "Limit your decisions.",
    "tag": "life",
    "content": "Every day we face endless decisions, what to eat, drink, wear, say, how to behave etc. All of that can be quite overwhelming for our minds. To prevent decision fatigue you can create a fixed routine for recurring decisions.",
  },
  {
    "id": 17,
    "title": "Read more.",
    "tag": "calm",
    "content": "If you look for the best way to quickly escape reality, read a book. It engages most of your senses quickly. When you read a book you fantasize about it, your eyes and hands are occupied with it.",
  },
  {
    "id": 18,
    "title": "Limit your cabin fever.",
    "tag": "calm",
    "content": "Get out, walk, leave your house, drive somewhere, do something different.",
  },
  {
    "id": 19,
    "title": "Meditation.",
    "tag": "calm",
    "content": "Meditation is a lot of things. The simplest of all is to just focus on breathing and listen to it.",
  },
  {
    "id": 20,
    "title": "Limit notifications.",
    "tag": "productivity",
    "content": "Limit the notifications on your computer or phone to the ones you need. Plan chunks of time where do not disturb is enabled.",
  },
  {
    "id": 21,
    "title": "When you need to buy something.",
    "tag": "consuming",
    "content": "If you need something of value (e.g. a TV,a new phone or a expensive piece of clothing) wait for a couple of days or weeks (if it more expensive). \nThis will force you to consider if you needed the item in the first place.",
  },
  {
    "id": 22,
    "title": "Eat less sugar.",
    "tag": "health",
    "content": "You don't need so much of it. If you need much sugar, it is usually a sign that something else is wrong.",
  },
  {
    "id": 23,
    "title": "Prevent the flu.",
    "tag": "health",
    "content": "Don't touch your face and wash your hands often. Also keep your phone clean.",
  },
  {
    "id": 24,
    "title": "Keep your work at work.",
    "tag": "productivity",
    "content": "Don't let your work affect your personal life. Don't let any work related frustration out on your spouse or friends.",
  },
  {
    "id": 25,
    "title": "Lust, Attraction, Attachment",
    "tag": "relationships",
    "content": "Lust, Attraction, Attachment are the three stages of love and hormones. First comes lust, then attraction and finally attachment",
  },
  {
    "id": 26,
    "title": "Create free content.",
    "tag": "work",
    "content": "Try to create free content around your work that other people can get value of.",
  },
  {
    "id": 27,
    "title": "Do aerobic disciplines.",
    "tag": "health",
    "content": "Start running or biking or swimming or any other form of exercise where your heart rate is in the aerobic zone. Consider investing time into the Maffetone Method .",
  },
  {
    "id": 28,
    "title": "Example social media promotion.",
    "tag": "social-media",
    "content": "If you are an entrepreneur here is a simple example on how to get customers on your social media page. \nImagine you are running a restaurant. Print out a big sign: \n\nDo you have Instagram / Pinterest / Whatever ? \nUsually people will ask about it, when they do, reply that you can get a free desert if you put on your social media pager if you like it or not.",
  },
  {
    "id": 29,
    "title": "Write important stuff down.",
    "tag": "productivity",
    "content": "Write down stuff that need to get done. Don't rely on other people to remind you about them.",
  },
  {
    "id": 30,
    "title": "Use a calendar.",
    "tag": "productivity",
    "content": "If you need to be somewhere at a specific time, put it on your calendar.",
  },
  {
    "id": 31,
    "title": "Selling to representatives of cooperation's.",
    "tag": "work",
    "content": "If you want to sell something and you are talking to an employee of a cooperation you have to think about this: \nThe person you are talking to will not be credited with the gains of what you are selling but only the downfalls. The result is that he/she can get fired. This makes it hard to sell anything that way.",
  },
  {
    "id": 32,
    "title": "Future or Present value.",
    "tag": "life",
    "content": "When you try to predict the future, consider that the present is here. The probability of it existing is very high, you can feel it and see it. \nThe closer the present is to now, the higher the probability is that you can predict it.",
  },
  {
    "id": 33,
    "title": "How to be happy.",
    "tag": "life",
    "content": "People don't want to be happy. What people actually are looking for is to have less negative emotions. \nThey don't want to be miserable, they want to be free of pain, free of anxiety.",
  },
  {
    "id": 34,
    "title": "Negotiations.",
    "tag": "life",
    "content": "Nobody is leaving happy from a negotiation if both parties have skin in the game.",
  },
  {
    "id": 35,
    "title": "World views can be different.",
    "tag": "life",
    "content": "Your perception of the world is based on your personality. That is why different personalities have different world views.",
  },
  {
    "id": 36,
    "title": "The old has value.",
    "tag": "life",
    "content": "If something is old, it will probably be around for quite some time. A book that exists for 100 years will also be around in the next 100 years. This is called the Lindy effect.",
  },
  {
    "id": 37,
    "title": "Who to hire #1.",
    "tag": "work",
    "content": "When you know what the job is about, hire somebody that is conscientious and conservative.",
  },
  {
    "id": 38,
    "title": "Who to hire #2.",
    "tag": "work",
    "content": "When you don't know what the job is about, hire somebody that is liberal and creative.",
  },
  {
    "id": 39,
    "title": "Trust yourself.",
    "tag": "productivity",
    "content": "Trusting in your own capabilities is to command yourself and know that you will execute and accomplish it.",
  },
  {
    "id": 40,
    "title": "Don't be like a clock.",
    "tag": "life",
    "content": "Resist the urge to suppress randomness. Don't create a strict schedule for everything.",
  },
  {
    "id": 41,
    "title": "You are not only paying taxes to the government.",
    "tag": "life",
    "content": "Everything we accomplish has a price attached to it. If you are famous you will pay rumors and gossip tax. If you have more than you need or things that others desire, theft is one possible tax you may have to pay. In light of success you pay taxes in stress and problems.",
  },
  {
    "id": 42,
    "title": "Enjoy the journey of life.",
    "tag": "life",
    "content": "A lot of times you will enjoy the journey towards a goal more than the actual goal.",
  },
  {
    "id": 43,
    "title": "Ups and downs.",
    "tag": "life",
    "content": "Your life is a like a cardiogram. It has ups and downs. There is no flat line. Keep in mind that no bad will endure forever but also don't forget that the good parts of your life may end.",
  },
  {
    "id": 44,
    "title": "Positive emotions and negative emotions.",
    "tag": "health",
    "content": "Humans have two kinds of emotions. Positive and negative emotions. By nature humans are usually more sensitive to bad emotions. This is why you forget the hundreds of compliments but remember that one negative comment.",
  },
  {
    "id": 45,
    "title": "Success comes in pairs.",
    "tag": "work",
    "content": "If you are successful at one thing, then more opportunities will come your way. You will meet more people and more people open even more doors for you which lead to the manifestation of more opportunities.",
  },
  {
    "id": 46,
    "title": "Emergency fund.",
    "tag": "life",
    "content": "Try to have money aside for at least 3 months of expenses. You never know what might happen.",
  },
  {
    "id": 47,
    "title": "Look at the bight side.",
    "tag": "health",
    "content": "Don't let yourself down, humans have never been healthier, happier, wealthier than any other humans in history.",
  },
  {
    "id": 48,
    "title": "Find your mission.",
    "tag": "life",
    "content": "Find your mission and stick to it. That might be creating a family or any other cause. Pick one and make it your main focus. There are also other things that you will encounter around your mission, evaluate them but don't go down the rabbit hole.",
  },
  {
    "id": 49,
    "title": "A stable partner.",
    "tag": "relationships",
    "content": "Be with a partner that is emotional stable and mature. He/she should have figured out his/her emotional problems and be mentally healthy.",
  },
  {
    "id": 50,
    "title": "Avoid bad relationships.",
    "tag": "relationships",
    "content": "Never ever be with someone that is jealous, needy or a stalker.",
  },
  {
    "id": 51,
    "title": "Less is more.",
    "tag": "consuming",
    "content": "Less is more can be applied to everything, start using it more deliberately throughout your day and life.",
  },
  {
    "id": 52,
    "title": "People like a good story.",
    "tag": "work",
    "content": "Humans care about narratives and a good story, but reality only cares about results and getting things done.",
  },
  {
    "id": 53,
    "title": "How to be happier.",
    "tag": "life",
    "content": "It is easier to answer the question what makes you unhappy instead of what makes you happy. This is enough and you can improve on that. Identify what makes you unhappy and remove it, as a result this will make you instantly happier.",
  },
  {
    "id": 54,
    "title": "Beware of the storyteller.",
    "tag": "life",
    "content": "There is a concept called survivor bias. It is the same as the winner is writing the history. The book how I lost 15kg in one week will make it to stores so you can read about it. You will not find a book titled how I lost 15kg in 10 years. \nFor every story of success there are a lot of stories of failure.",
  },
  {
    "id": 55,
    "title": "Signal to noise ratio.",
    "tag": "productivity",
    "content": "Keep in mind the signal to noise ratio that you have for a particular topic. When you read the news every morning, you have a lot of data but also a lot of noise which may hide the signal. Important stuff will always reach you when needed.",
  },
  {
    "id": 56,
    "title": "Always have an option.",
    "tag": "life",
    "content": "Try to have an option around your choices. Don't be forced into using a particular one. On the other hand, if there is no viable alternative, don't sweat it.",
  },
  {
    "id": 57,
    "title": "What is a Black swan.",
    "tag": "life",
    "content": "Usually a swan is white, but once in a while a black swan comes along . This holds true for the animal but also for our society. A black swan event is a major event that changes society and the way we think about the world.",
  },
  {
    "id": 58,
    "title": "Your employer is keeping a grudge.",
    "tag": "work",
    "content": "Every mistake you make when you are working for someone or a cooperation will go on your permanent record which will follow your around. Now imagine a freelancer, he/she can make a mistake but can always try to make up for it and pick up a new client.",
  },
  {
    "id": 59,
    "title": "When to help.",
    "tag": "life",
    "content": "Always keep in mind that when you are trying to help there is a possibility that you are causing more harm.",
  },
  {
    "id": 60,
    "title": "Do not fix that.",
    "tag": "life",
    "content": "Don't fix something that is not broken, or things that rather should be left alone.",
  },
  {
    "id": 61,
    "title": "Don't be offended.",
    "tag": "life",
    "content": "When you thing that what you are hearing is offending you. Stop and ask yourself this \n1. Is it true? If yes why be upset about the truth. \n2. Is it a lie? If yes why be upset about a lie.",
  },
  {
    "id": 62,
    "title": "Non Violent Communication.",
    "tag": "life",
    "content": "Start to practice NVC (Non violent communication). The NVC process consists of 4 items \n1. Observe \n2. Feel \n3. Identify needs \n4. Make a request",
  },
  {
    "id": 63,
    "title": "Observe or evaluate.",
    "tag": "relationships",
    "content": "When you talk to someone, be precise in how you articulate yourself. Observe the actions of what you want to say. For instance Peter is sitting in the chair, is an observation. Peter is lazy cause he is sitting in the chair is an evaluation. We are criticizing instead of just observing.",
  },
  {
    "id": 64,
    "title": "Distinguish feelings from thoughts.",
    "tag": "relationships",
    "content": "I feel misunderstood is not an accurate statement. There is no feeling which is called misunderstood. You may feel anxious, frighten or any positive or negative emotion. When you have identified what you are feeling then you can also address it better.",
  },
  {
    "id": 65,
    "title": "Find the needs.",
    "tag": "relationships",
    "content": "We usually have our focus on analyzing level of wrongness rather than needs. If my partner wants more affection and attention we are internally evaluating this as needy and dependant.",
  },
  {
    "id": 66,
    "title": "Quickly evaluate a business.",
    "tag": "work",
    "content": "Ask the below question to quickly evaluate a business:\n1. Did it invent a service? \n2. Did it invent a formula? \n3. Did it invent a delivery method? \n4. Is anything what the business doing patentable or a unique user experience? \n5. Did the business find an isolated market segment? \n6. Is the concept branded?",
  },
  {
    "id": 67,
    "title": "Two choices.",
    "tag": "life",
    "content": "1. You can focus on how many times you have been wronged. \nor \n2. Work with what you have got.",
  },
  {
    "id": 68,
    "title": "Emotional eater.",
    "tag": "parenting",
    "content": "Don't celebrate events with your children by eating food. If something bad happens don't cheer them up with food.",
  },
  {
    "id": 69,
    "title": "Learn from your children.",
    "tag": "parenting",
    "content": "Learn from your children as soon as possible, they may have a different angle on stuff than you, which is always helpful.",
  },
  {
    "id": 70,
    "title": "Own your rudeness.",
    "tag": "parenting",
    "content": "When you are rude to your children, acknowledge it, respect your children and be a role model. Imagine if somebody would be rude to you, how would it make you feel.",
  },
  {
    "id": 71,
    "title": "Be humble.",
    "tag": "parenting",
    "content": "If you don't react well to your children in a given situation, ask them what you did wrong and how it made them feel. The goal is for the children to express their feelings in a safe environment.",
  },
  {
    "id": 72,
    "title": "Touch is analgesic.",
    "tag": "health",
    "content": "When you are grieving get a massage. Touch itself is analgesic for the human mind and body. Infants may day if they are not touched and taken care of.",
  },
  {
    "id": 73,
    "title": "Anxious children.",
    "tag": "parenting",
    "content": "When an adult comes into the room and a children stop playing and talking is an indicator that they might be anxious or frighten. Children that aren't anxious and well adjusted will continue playing.",
  },
  {
    "id": 74,
    "title": "Negative emotions.",
    "tag": "health",
    "content": "Here are some examples of manifestation of negative emotions: \n\nFrustration - when approaching a goal and you are blocked.\nDisappointment - when pursuing a goal and it doesn't work.\nGrief - when loosing something.\nPain - marker for damage.\nAnxiety - marker for the possibility of damage.",
  },
  {
    "id": 75,
    "title": "Be aware of the black swan.",
    "tag": "work",
    "content": "When somebody says that this never happened before is no indicator that it may not happen in the future. When white swan's are observed, doesn't mean there aren't any black.",
  },
  {
    "id": 76,
    "title": "Reduce risk.",
    "tag": "work",
    "content": "In the absence of reliable data one must reduce risk.",
  },
  {
    "id": 77,
    "title": "Minority rule.",
    "tag": "work",
    "content": "Watch out for the minority rule. Once you have 10% or more woman at a party you cannot only serve beer. Most men although will drink wine. So you only need one set of glasses if you will serve only wine.",
  },
  {
    "id": 78,
    "title": "Winter is coming.",
    "tag": "life",
    "content": "Game of thrones got it right. You always have to prepare for the winter. When times are good go the extra mile and try to grow as much as possible. When they go bad you have to accept that you may not grow. The game then is to try to survive.",
  },
  {
    "id": 79,
    "title": "Create something that will last.",
    "tag": "work",
    "content": "If you want to create something that will last for a long time, target something that will work today and would have worked also 30 years ago.",
  },
  {
    "id": 80,
    "title": "Skin in the game.",
    "tag": "life",
    "content": "Skin in the game is when somebody has both traits of an outcome. \nPositive outcome - reaping the benefits. \nNegative outcome - paying a price.\nYou can't and shouldn't be able to transfer the risk.",
  },
  {
    "id": 81,
    "title": "Scalable jobs.",
    "tag": "work",
    "content": "There are two kinds of jobs. The ones that are affected by gravity and the ones that are not. For instance a doctor will only heal so many patients he/she has time for. A baker will create one bread for each customer. On the other hand a writer will create once the book and it will be read by thousands. The difference is the scalability of each job. You have to know in what profession you are so you can plan accordingly.",
  },
  {
    "id": 82,
    "title": "Just launch.",
    "tag": "productivity",
    "content": "Let yourself of the hook. Don't try to be perfect. Publish that project you have lying around. You will be the hardest judge on yourself. Don't let it get the best of you. There is always something which you can change or adjust.",
  },
  {
    "id": 83,
    "title": "Stop bad behavior.",
    "tag": "relationships",
    "content": "Don't judge people, don't condemn people and also don't whine about people.",
  },
  {
    "id": 84,
    "title": "Loosing weight.",
    "tag": "health",
    "content": "If you are on a caloric deficit in order to loose weight watch out for the small stuff you may do every day that will add up calories. If you put milk in your coffee that could be ok, but if you drink five coffees per day then this can add up.",
  },
  {
    "id": 85,
    "title": "Remove multiple alarms.",
    "tag": "productivity",
    "content": "Remove multiple alarms from your phone. When you wake up have the willpower to get up on your own. Be the one that is in control, not the alarm.",
  },
  {
    "id": 86,
    "title": "Insurance.",
    "tag": "consuming",
    "content": "There is the mandatory insurance which you need for your car. There is no way around it. There is also insurances which you should buy for catastrophic events. Events that may happen and you will not be able to recover. Get insurance for those types of events.",
  },
].shuffle().slice(0, 10)




