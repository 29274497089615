import React from "react"
import PropTypes from "prop-types"

const boldFont = {
  fontFamily: "Libre Baskerville Bold",
  fontWeight: "700",
  flexGrow: "1",
}

const ArticleTitle = ({ text }) => {
  return (
    <h1
      align="start"
      style={boldFont}
    >{text}</h1>
  )
}

ArticleTitle.propTypes = {
  text: PropTypes.string.isRequired,
}

export default ArticleTitle
