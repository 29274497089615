import { posts } from "../assets/content"

export function getAllUniqueTags() {
  const tags = posts.map((item) => item.tag)
  const uniqueTags = Array.from((new Set(tags)))
  uniqueTags.unshift("All")
  return uniqueTags
}

export function getFilteredList(tag) {
  if (tag === "All") {
    return posts
  }
  return posts.filter(item => item.tag === tag)
}

export function getDefaultTag() {
  return "All"
}

export function getRandomNumberForPost() {
  return Math.floor(Math.random() * posts.length)
}

export function getPost(index) {
  if (index <= posts.length) {
    return posts[index]
  }
}
