import React, { Component } from "react"
import MutedText from "./MutedText"
import ArticleTitle from "./ArticleTitle"
import ShareIcon from "../images/share-white-icon.svg"

export default class Post extends Component {

  state = {
    isTextCopied: false,
  }

  share = () => {
    let text = "https://adviceworthgiving.com#" + this.props.post.id

    if (navigator.share) {
      navigator.share({
        title: this.props.post.title,
        url: text,
      })
        .catch(console.error)
    } else {
      this.setState({ isTextCopied: true })
      navigator.clipboard.writeText(text).then(
        setTimeout(
          function() {
            this.setState({ isTextCopied: false })
          }
            .bind(this),
          2000,
        ),
      ).catch(console.error)
    }
  }

  renderShareButton() {
    if (this.state.isTextCopied) {
      return (
        <h3 style={
          {
            marginTop: "10px",
            marginBottom: "10px",
          }
        } align="start">
          <MutedText text={"Link copied"}/>
        </h3>
      )
    } else {
      return (
        <button
          style={button}
          onClick={this.share}>
          <img style={{
            height: "24px",
            width: "24px",
          }} src={ShareIcon} alt="share"/>
        </button>
      )
    }
  }

  render() {
    return (
      <div id={this.props.post.id} style={{ marginBottom: "150px" }}>
        <div style={row}>
          <ArticleTitle text={this.props.post.title}/>
          {this.renderShareButton()}
        </div>
        <h3 align="start"><MutedText text={this.props.post.tag}/></h3>
        <p style={newLine}>{this.props.post.content}</p>
        <hr style={divider}/>
      </div>
    )
  }
}

const divider = {
  width: "40%",
  height: "1px",
  border: "none",
  backgroundColor: "#7f8ea3",
  marginLeft: "0",
  marginTop: "30px",
}

const newLine = {
  whiteSpace: "pre-wrap",
}

const row = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  width: "100%",
}

const button = {
  width: "48px",
  height: "48px",
  textAlign: "start",
  border: "none",
  background: "none",
  cursor: "pointer",
  padding: 0,
}
